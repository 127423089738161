<template>
  <div id="home" v-infinite-scroll="getListData" class="home scrollbar_box">
    <div class="nav_wrap">
      <Guide />
    </div>
    <div class="banner_wrap">
      <div id="swiper" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="item in data.bgList" :key="item" class="swiper-slide">
            <div class="item">
              <img :src="item" alt="" class="img" />
            </div>
          </div>
        </div>
      </div>
      <div v-show="!data.videoPlaying" class="banner_content">
        <div class="title">落落的碎碎念</div>
        <div class="tips">
          不负此生 不负此心
        </div>
      </div>
    </div>
    <div class="content_wrap">
      <div class="content_box">
        <div class="auth_box">
          <Auth></Auth>
          <div class="weather_box">
            <div id="he-plugin-standard"></div>
          </div>
        </div>
        <div class="article_box">
          <Topic :list="data.articleList"></Topic>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script lang="ts">
import Swiper, { Autoplay } from 'swiper/core';
import 'swiper/swiper.min.css';

import Auth from '@/views/layer/auth.vue';
import Topic from '@/views/layer/topic.vue';
import Footer from '@/views/layer/footer.vue';
import Guide from '@/views/layer/guide.vue';

import { articleList } from '@/services/api';
import CanvasVideo from '@/utils/video';

export default defineComponent({
  name: 'Home',
  components: {
    Guide,
    Auth,
    Topic,
    Footer,
  },
  async setup() {
    const data: {
      bgList: Array<string>,
      paginationImg: string,
      articleList: TArticle[],
      canvasVideo: CanvasVideo | null,
      videoPlaying: boolean,
      parameters: {
        pageSize: number,
        pageIndex: number,
      },
      listIsOver: boolean,
    } = reactive({
      bgList: [
        '/static/images/bg1.jpg',
        '/static/images/bg2.jpg',
        '/static/images/bg3.jpg',
        '/static/images/bg4.jpg',
      ],
      swiper: null,
      paginationImg: '/static/images/ic_comic.png',
      articleList: [],
      canvasVideo: null,
      videoPlaying: false,
      parameters: {
        pageSize: 10,
        pageIndex: 1,
      },
      listIsOver: false,
    });

    // 获取最近的十篇文章
    async function getListData():Promise<void> {
      if (data.listIsOver) {
        return;
      }
      const { data: { list, pageIndex, pageSize, total } } = await articleList(data.parameters);
      data.articleList = data.articleList.concat(list);
      if (total <= (pageIndex * pageSize)) {
        data.listIsOver = true;
      } else {
        data.parameters.pageIndex++;
      }
    }
    await getListData();
    const play = () => {
      if (data.canvasVideo) {
        data.canvasVideo.play();
      }
    };

    return { data, play, getListData };
  },
  mounted() {
    Swiper.use([Autoplay]);
    // eslint-disable-next-line no-new
    new Swiper('#swiper', {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
      },
      observer: true,
      observeParents: true,
    });

    // 延迟加载和风天气插件
    setTimeout(() => {
      window.WIDGET = {
        CONFIG: {
          layout: '2',
          width: 230,
          height: 270,
          background: '1',
          dataColor: 'FFFFFF',
          borderRadius: '5',
          key: 'bce711ed802b4e3cad00c960de7a0c86',
        },
      };

      (function(d) {
        const c = d.createElement('link');
        c.rel = 'stylesheet';
        c.href =
        'https://widget.heweather.net/standard/static/css/he-standard.css?v=1.4.0';
        const s = d.createElement('script');
        s.src =
        'https://widget.heweather.net/standard/static/js/he-standard.js?v=1.4.0';
        const sn = d.body;
        sn.appendChild(c);
        sn.appendChild(s);
      })(document);
    }, 1000);
  },
});
</script>
<style lang="less" scoped>
@import "../styles/config.less";
.home {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  .nav_wrap {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
  }
  .banner_wrap {
    width: 100%;
    height: 100%;
    position: relative;
    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-slide {
        width: 100%;
        height: 100%;
        .item {
          width: 100%;
          height: 100%;
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .video {
            width: 100%;
            height: 100%;
          }
        }
      }
      .swiper-pagination {
        bottom: 5%;
        line-height: 0;
        :deep(.swiper-pagination-bullet ){
          display: inline-block;
          margin-left: 0.2rem;
          width: 0.15rem;
          height: 0.15rem;
          border: 0.02rem solid transparent;
          border-radius: 50%;
          background-color: @c_tips;
          vertical-align: middle;
          cursor: pointer;
          transition: all 0.2s;
          opacity: 0.8;
          &:hover {
            border: 0.02rem solid #fff;
            background-color: @c_active;
            transform: scale(1.3);
          }
          .img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: none;
          }
          &.swiper-pagination-bullet-active {
            border-width: 0;
            border-color: transparent;
            transform: scale(1.8);
            background-color: transparent;
            .img {
              display: block;
            }
          }
        }
      }
    }
    .banner_content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      width: 6rem;
      height: 3rem;
      max-width: 80%;
      max-height: 60%;
      margin: auto;
      background-color: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 0.02rem 0.08rem rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.1rem;
      .title {
        font-size: 0.3rem;
        color: #333;
        font-weight: bold;
        line-height: 2;
        margin-bottom: 0.2rem;
      }
      .tips {
        padding: 0.05rem 0;
        font-size: 0.14rem;
        color: #666;
        line-height: 1.5;
      }
    }
  }
  .content_wrap {
    .content_box {
      max-width: @minWidth;
      margin: auto;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0.3rem;
      .auth_box {
        width: 25%;
        .weather_box {
          width: 100%;
          margin-top: 0.1rem;
          border-radius: 0.1rem;
        }
      }
      .article_box {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
@media (max-width: 1200px) {
  .home {
    .content_wrap {
      .content_box {
        display: block;
        padding: 0.15rem;
        .auth_box {
          width: 100%;
          padding-bottom: 0.15rem;
          .music {
            display: none;
          }
        }
      }
    }
  }
}
</style>
