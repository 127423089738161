<template>
  <div class="auth">
    <div class="headportrait">
      <img :src="data.logoUrl" alt="" class="img" />
    </div>
    <div class="name">落落的碎碎念</div>
    <div class="text">不负此生 不负此心</div>
    <div class="account_box">
      <div class="item">
        <a
          class="icon_box"
          href="https://space.bilibili.com/273745872"
          target="_blank"
        >
          <svg-icon :icon-class="'bilibili'"></svg-icon>
        </a>
      </div>
      <div class="item">
        <a
          class="icon_box"
          href="https://weibo.com/u/7018404505"
          target="_blank"
        >
          <svg-icon :icon-class="'weibo'"></svg-icon>
        </a>
      </div>
      <div class="item">
        <a
          class="icon_box"
          href="https://github.com/sos-luoluo"
          target="_blank"
        >
          <svg-icon :icon-class="'github'"></svg-icon>
        </a>
      </div>
      <div class="item">
        <a class="icon_box" href="mailto:re_an@qq.com">
          <svg-icon :icon-class="'email'"></svg-icon>
        </a>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  name: 'Auth',
  setup() {
    const data : {
      logoUrl: string
    } = reactive({
      logoUrl: '/static/images/logo.png',
    });

    return { data };
  },
});
</script>
<style lang="less" scoped>
@import "../../styles/config.less";
.auth {
  width: 100%;
  padding: 0.3rem 0.1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.1rem;
  .headportrait {
    padding: 0.1rem 0;
    .img {
      display: block;
      width: 0.85rem;
      height: 0.85rem;
      border-radius: 50%;
      border: 0.01rem solid @c_bg_line;
      box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover {
        box-shadow: 0 0.02rem 0.1rem 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
  .name {
    padding: 0.1rem 0;
    text-align: center;
    font-size: 0.2rem;
    color: @gray;
    line-height: 1.2;
    font-weight: bold;
  }
  .text {
    padding: 0.1rem 0;
    text-align: center;
    font-size: @f_text;
    color: @c_bg_title;
    line-height: 1.2;
  }
  .account_box {
    width: 100%;
    padding: 0.1rem 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      .icon_box {
        display: block;
        font-size: 0.5rem;
        color: @c_title;
        cursor: pointer;
        &:hover {
          color: @c_active;
        }
        :deep(.iconfont){
          font-size: 0.3rem;
        }
      }
    }
  }
}
</style>
